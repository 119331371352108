/**
 * New and improved Signal R Provider.
 * 
 * This will only connect if an identity is provided
 * And on logout, if a connection exists, it will stop it
 * It needs to be contained after the Apollo Wrapper that provides identity
 * 
 * To use:
 * 
 * In layout:
 *  import { SignalRProvider } ..........;
 * After apollo wrapper, add <SignalRProvider> and put the close tag just above apollo
 *    <ApolloWrapper>
 * 		<SignalRProvider>
 * 			...
 * 		</SignalRProvider>
 * 	 </ApolloWrapper>
 * 
 * In component:
 * import { useSignalR } ........;
 * .
 * .
 * .
 * function Component() {
 *	// this returns an object:  { connection:signal_r_instance, WsState:string[Disconnected|Connected|Connecting]}
 *   //											Note: WsState is a useState in the provider, so you can use this as a hook in a component for state changes, see the useEffect exmaple below
 *   const signalr = useSignalR();
 * .
 * .
 * .
 *   const onSomeEventHandler = (data)=>{}
 *   useEffect(()=>{
 *        // Set Events on provider state change
 *   	if (signalr.WsState == 'Connected') {
 * 			signalr.connection.on('some_event',onSomeEventHandler);
 * 		}
 *   },[signalr.WsState]);
 *   useEffect(()=>{
 * 		// Clean Up Events when component is unloaded
 * 		return ()=>{
 * 			if (signalr.WsState == 'Connected') {
 * 				signalr.connection.off('some_event', onSomeEventHandler);
 * 			}
 * 		}
 *   },[]);
 * }
 * 
 */
import React, { useContext, createContext, useRef, useState, useEffect } from 'react';
import * as signalR from "@microsoft/signalr";
import { PmStore } from "@/state";

export interface ProvidersProps {
	children: React.ReactNode;
}

export const SignalRContext = createContext({ connection: {}, state: 'Disconnected' });

export const SignalRProvider = ({ children }: ProvidersProps) => {

	const state = PmStore();
	// const [wsUrl, setWsUrl] = useState('/public/property-manager');  /** @todo maybe?  Get this default value from a config, if it will never change, we could remove this.  You could leave and allow a component to trigger a connection change */
	const [wsUrl, setWsUrl] = useState('/public/tenant');  /** @todo maybe?  Get this default value from a config, if it will never change, we could remove this.  You could leave and allow a component to trigger a connection change */
	const [WsState, setWsState] = useState('Disconnected');
	const connection = useRef(null);

	const getToken = async () => {
		const token = state.token;
		return token;
	}
	function buildConnection(url) {
		const baseUrl = (window.location.hostname == 'localhost') ?
			'https://rentalflow.dwai.xyz' : 'https://' + window.location.hostname;
		return new signalR.HubConnectionBuilder()
			.withUrl(baseUrl + url, {
				accessTokenFactory: async () => await getToken(),
			})
			.configureLogging(signalR.LogLevel.Debug)
			.withAutomaticReconnect()
			.build()
	}
	useEffect(() => {
		if (state.token) {
			connection.current = buildConnection(wsUrl);
			connection.current.start()
				.then(() => {
					console.log('SignalR Started');
					connection.current.onclose((e) => console.log('SignalR Closed: ', e));
					setWsState(connection.current.state);
				})
				.catch(err => {
					console.log('SignalR Failed to connect: ', err);
				});
		} else if (WsState !== 'Disconnected') {
			connection.current.stop().then(() => {
				console.log('SignalR Stopped');
				setWsState(connection.current.state);
			});
		}
	}, [state]);

	return (
		<SignalRContext.Provider value={{ connection: connection.current, WsState }}>
			{children}
		</SignalRContext.Provider >
	)
};

export function useSignalR() {
	return useContext(SignalRContext);
}