"use client";
import type { Metadata } from "next";

import { Inter } from "next/font/google";
import "./globals.css";
import TopNavigationBar from "@/components/topnav/TopNavigationBar";
import { ApolloWrapper } from "@/app/ApolloWrapper";
import React, { useEffect, useRef, useState } from "react";
import { Toaster } from "react-hot-toast";
import { PmStore } from "@/state";
import { apiGetCurrentOrganization } from "@/api/organization/GetCurrentOrganization";
import { Spinner } from "@material-tailwind/react";
import { SignalRProvider } from "./SignalRProvider";


const inter = Inter({ subsets: ["latin"] });

// export const metadata: Metadata = {
//   title: "Create Next App",
//   description: "Generated by create next app",
// };
//
//






export default function RootLayout({ children, }: Readonly<{ children: React.ReactNode; }>) {
	const state = PmStore();
	const [OrgConnected, SetOrgConnected] = useState(false);

	const selectedOrgRef = useRef(PmStore.getState().selectedOrganization);
	// Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
	useEffect(() => PmStore.subscribe(
		(state) => (selectedOrgRef.current = state.selectedOrganization)
	), [])


	function GetOrganizations() {
		apiGetCurrentOrganization().then((data) => {
			state.setSelectedOrg(data);
			SetOrgConnected(true)
		});
	}




	useEffect(() => {

		if (!selectedOrgRef.current) {
			GetOrganizations();
		}
		else {
			SetOrgConnected(true)
		}
	}, []);






	return (
		<html lang="en">
			<head>
				<title>Rental Flow</title>
				<link
					rel="icon"
					href="/logo.svg"
					type="image/svg"
				/>
				<script
					id="zumrailssdk"
					src="https://cdn.zumrails.com/production/zumsdk.js"
					type="text/javascript"
					async=""
				></script>


			</head>


			<body className={inter.className}>
				<ApolloWrapper>
					<SignalRProvider>
						<Toaster />
						<TopNavigationBar />

						{OrgConnected ? <div>

							<div className={"p-1 h-[calc(100vh-4rem)] "}>
								{children}
							</div>
						</div> : <div className={"flex justify-center mt-10"}>
							<Spinner />
						</div>}
					</SignalRProvider>
				</ApolloWrapper>
			</body>
		</html>
	);
}
